<template>
  <div>
    <div class="top-bar">
      <v-row
        no-gutters
        class="ma-3"
      >
        <v-col class="mr-5">
          <v-select
            :value="getSelectedDataset"
            :items="getDatasets"
            :item-text="getDatasetFieldText"
            label="Filter by Dataset"
            hide-details
            @change="updateDatasetFilter"
            return-object
            clearable
          />
          <!-- <v-checkbox
          class="ml-5"
          :value="getFilterOnDataset"
          label="Filter on dataset"
          outlined
          hide-details
          @change="updateDatasetFilterCheckbox"
        /> -->
        </v-col>
        <v-col class="mr-5">
          <v-select
            :value="getSchemaFilter"
            :items="getLabelSchemasWithAll"
            label="Schema"
            hide-details
            @change="updateSchemaFilter"
          />
        </v-col>
        <v-col>
          <v-select
            :value="getStatusFilter"
            :items="status_filters_options"
            label="Status"
            hide-details
            @change="updateStatusFilter"
          />
        </v-col>
        <v-col>
          <v-checkbox
            class="ml-5"
            :value="getOrderByCreatedAt"
            label="Order by date"
            outlined
            hide-details
            @change="updateOrderByCreatedAt"
          />
        </v-col>
      </v-row>
    </div>

    <div class="container pb-180p">
      <v-data-table
        :headers="headers"
        :items="getTasks"
        :items-per-page="getTasksPerPage"
        hide-default-footer
        :page="getPage"
        class="elevation-1"
        @click:row="handleClickRow"
        style="cursor: pointer"
        multi-sort
      ></v-data-table>
    </div>
    <div class="footer">
      <v-pagination
        :value="getPage"
        :length="getNbPages"
        @input="updatePage"
      ></v-pagination>
      <div class="input">
        <v-text-field
          :value="getTasksPerPage"
          label="Items per page"
          type="number"
          min="1"
          max="100"
          @change="updateNbTasksPerPage"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'labeler',
  computed: {
    ...mapGetters('labels', ['getLabelSchemas']),
    ...mapGetters('auth', ['getUserId']),
    ...mapGetters('datasets', ['getDatasets', 'getSelectedDataset']),
    ...mapGetters('tasks', [
      'getTasks',
      'getPage',
      'getTasksPerPage',
      'getNbPages',
      'getSchemaFilter',
      'getStatusFilter',
      'getOrderByCreatedAt',
      'getFilterOnDataset',
    ]),
    getLabelSchemasWithAll: function () {
      let label_schema = Array.from(this.getLabelSchemas)
      label_schema.push('all')
      return label_schema
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Transaction',
          align: 'start',
          value: 'tx_description',
          sortable: false,
        },
        { text: 'Task', value: 'schema_version' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'updated_at' },
      ],
      status_filters_options: ['all', 'todo', 'done', 'in_review', 'cancelled'],
    }
  },
  methods: {
    ...mapActions('labels', ['retrieveLabelSchemas']),
    ...mapActions('tasks', ['retrieveTasksForLabeler']),
    ...mapActions('datasets', [
      'retrieveDatasets',
      'retrieveDatasetTransactionIds',
    ]),
    ...mapMutations('datasets', ['setSelectedDataset']),
    ...mapMutations('tasks', [
      'setTasksPerPage',
      'setPage',
      'setSchemaFilter',
      'setStatusFilter',
      'setTask',
      'setCurrentLabelerId',
      'setOrderByCreatedAt',
      'setFilterOnDataset',
    ]),
    async retrieveTasks() {
      try {
        // TODO: check if labeler in path to retrieve id => assert Admin else redirect
        // else normal labeler dashboard

        // normal labeler mode
        this.setCurrentLabelerId(this.getUserId)
        await this.retrieveTasksForLabeler()
        this.error = false
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    async updateNbTasksPerPage(e) {
      e = parseInt(e)
      if (e != '') {
        if (e < 1) {
          e = 1
        }
        if (e > 100) {
          e = 100
        }
        this.setTasksPerPage(e)
        await this.retrieveTasks()
      }
    },
    async updatePage(e) {
      this.setPage(e)
      await this.retrieveTasks()
    },
    async updateSchemaFilter(e) {
      this.setSchemaFilter(e)
      await this.retrieveTasks()
    },
    async updateOrderByCreatedAt(e) {
      this.setPage(1)
      if (e == null) {
        e = false
      }
      this.setOrderByCreatedAt(e)
      await this.retrieveTasks()
    },
    async updateDatasetFilter(e) {
      this.setPage(1)
      this.setSelectedDataset(e)
      await this.retrieveTasks()
    },
    async updateDatasetFilterCheckbox(e) {
      if (e == false || e == null) {
        this.setSelectedDataset(null)
      }
      if (e == null) {
        this.setFilterOnDataset(false)
      } else {
        this.setFilterOnDataset(e)
      }
      await this.retrieveTasks()
    },
    async updateStatusFilter(e) {
      this.setStatusFilter(e)
      await this.retrieveTasks()
    },
    handleClickRow(value) {
      this.$router.push(`/task/${value.id}`)
    },
    getDatasetFieldText(item) {
      return `${item.name} - (${item.size} txs) - ${item.updated_at}`
    },
  },
  async created() {
    await this.retrieveDatasets()
    if (this.getSelectedDataset != null) {
      await this.retrieveDatasetTransactionIds()
    }
    if (this.getLabelSchemas == null || this.getLabelSchemas.length == 0) {
      this.retrieveLabelSchemas()
    }
    await this.retrieveTasks()
  },
}
</script>

<style></style>